import React, { FC, useEffect } from 'react'
import LogoutBtn from '@cmp/_shared/logout/LogoutBtn'
import cn from 'classnames'
import { useStore } from '@store/index'
import useSound from 'use-sound'
import sound from '@assets/audio/sound.mp3'
import { usePrevious } from '@src/hooks'
import { Link } from 'react-router-dom'
import { PATHS } from '@src/config/nav'
import ReactGA from 'react-ga'
import styles from './SubHeader.module.scss'
// import SubNav from './subnav/SubNav'


const SubHeader : FC = () => {
  const [ { user } ]:any = useStore()
  const { authorizeSound } = user.supplier.spottiesPreferences
  const prevAuthorizeSound:boolean = usePrevious(authorizeSound) || false

  const [ play ]:any = useSound(sound, {
    interrupt: true,
  })

  useEffect(() => {
    if (!prevAuthorizeSound && authorizeSound) {
      play()
    }
  }, [ play, authorizeSound ])

  const onNotifClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'click header notif',
    })
  }

  return (
    <div className={ styles.SubHeader }>
      {/* <SubNav /> */}
      <div />
      <div className={ styles.right }>
        <Link to={ PATHS.params.notifications } className={ cn(styles.btnNotif) } onClick={ onNotifClick }>
          <div className={ cn(styles.box, styles.notifs, authorizeSound && styles.active) }>
            <i className="icon-notifications" />
          </div>
        </Link>
        <div className={ styles.box }>
          <LogoutBtn />
        </div>
      </div>
    </div>
  )
}

export default SubHeader
