import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LINKS } from '@config/nav'
import LogoutBtn from '@cmp/_shared/logout/LogoutBtn'
import styles from './Nav.module.scss'

interface Props{
  toggleNav?:any
  isMobileSize?:boolean
}

const Nav : FC<Props> = ({ toggleNav = () => {}, isMobileSize = false }) => {
  const { pathname } = useLocation()

  return (
    <nav className={ styles.nav }>
      <ul>
        {LINKS.map(({
          to, label, icon, isMain = true, isMobile = true, isMobileOnly = false,
        }) => {
          if (!isMain) return null
          const isActive = pathname === to || (pathname.includes(to) && to !== '/')
          const className = isActive ? styles.active : undefined
          const isShown = isMobileSize ? isMobile : !isMobileOnly
          const extraProps = isMobileSize ? { onClick: toggleNav(false) } : {}
          return isShown && (
            <li key={ to }>
              <Link to={ to } className={ className } { ...extraProps }>
                <i className={ icon } />
                <span className={ `${styles.label}` }>{label}</span>
              </Link>
            </li>
          )
        })}
        {
          isMobileSize && (
            <li>
              <LogoutBtn />
            </li>
          )
        }
      </ul>
    </nav>
  )
}

export default Nav
