import React, { useState } from 'react'
import Nav from '@cmp/layouts/header/nav/Nav'
import { Link } from 'react-router-dom'
import { PATHS } from '@config/nav'
import logo from '@assets/images/logo-white.png'
import { useMuiWidth } from '@hooks/index'
import { isWidthDown } from '@material-ui/core/withWidth'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import cn from 'classnames'

import styles from './Header.module.scss'
import OpenCloseSign from './openCloseSign/OpenCloseSign'

const Header = () => {
  const [ isNavOpen, setIsNavOpen ] = useState(false)
  const width = useMuiWidth()

  const renderLogo = () => {
    return (
      <Link to={ PATHS.root } className={ `${styles.logo}` }>
        <img src={ logo } alt="logo" />
      </Link>
    )
  }

  const toggleNav = (bool:boolean) => () => {
    setIsNavOpen(bool)
  }

  const renderBurger = () => {
    return (
      <button onClick={ toggleNav(true) } className={ `${styles.burgerBtn}` }>
        <i className="icon-menu" />
      </button>
    )
  }

  const renderVersionHub = () => {
    const version = process.env.REACT_APP_VERSION
    return (
      <span className={ cn(styles.version, styles.versiontext) }>Version {version && version.substring(0, 7)}
      </span>
    )
  }

  const renderMobile = () => {
    return (
      <>
        <div className={ cn(styles.elmt, 'ml15') }>{renderBurger()}</div>
        <div className={ styles.elmt }>{renderLogo()}</div>
        <div className={ styles.elmt }><OpenCloseSign /></div>
        <SwipeableDrawer
          classes={ { paper: styles.drawerPaper } }
          anchor="left"
          open={ isNavOpen }
          onClose={ toggleNav(false) }
          onOpen={ toggleNav(true) }
        >
          <div className={ styles.mobileNavWrapper }>
            {renderLogo()}
            <Nav toggleNav={ toggleNav } isMobileSize />
            <div className={ styles.closeWrapper }>
              <button onClick={ toggleNav(false) } className={ cn('reset', styles.close) }>
                <i className="icon-close" />
              </button>
              {renderVersionHub()}
            </div>
          </div>
        </SwipeableDrawer>
      </>
    )
  }

  const renderDefault = () => {
    return (
      <>
        {renderLogo()}
        <OpenCloseSign />
        <Nav />
        {renderVersionHub()}
      </>
    )
  }
  
  return (
    <div className={ styles.header }>
      {isWidthDown('sm', width) ? renderMobile() : renderDefault()}
    </div>
  )
}
export default Header
