import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { makeStyles } from '@material-ui/core/styles'

interface Props{
  children:React.ReactElement
  fullScreen?:boolean
  dialogMaxWidth?:false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined,
}

const useStyles = makeStyles({
  paperDialog: {
    borderRadius: '0px !important',
  },
  rootContent: {
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column',
  },

})

const Modal: React.FC<Props> = ({
  children,
  fullScreen = false,
  dialogMaxWidth = 'sm',
}) => {
  const classes = useStyles()

  const [ open, setOpen ] = useState(true)
  
  // const handleClose = () => {
  //   setOpen(false)
  // }

  const childrenWithProps = React.cloneElement(children)

  return (
    <Dialog
      fullScreen={ fullScreen }
      open={ open }
      scroll="paper"
      fullWidth
      maxWidth={ dialogMaxWidth }
      classes={ {
        paper: classes.paperDialog,
      } }
    >
      <DialogContent classes={ {
        root: classes.rootContent,
      } }
      >
        {childrenWithProps}
      </DialogContent>
    </Dialog>
  
  )
}
export default withMobileDialog()(Modal)
