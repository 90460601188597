import React, { useEffect, useState } from 'react'
import useSound from 'use-sound'
import sound from '@assets/audio/sound.mp3'
import { useStore } from '@store/index'
import { useSnackbar } from 'notistack'
import cn from 'classnames'
import { ICONS } from '@src/config/theme'
import Modal from '@src/components/ui/modal/Modal'
import styles from './GlobalNotification.module.scss'
import NotificationOrderToApprove from './ordersApproval/NotificationOrderToApprove'

interface Props{
  prevOrdersCount:number
  ordersCount:number,
  orderToBeApproved:any
}

const GlobalNotification: React.FC<Props> = ({ prevOrdersCount, ordersCount, orderToBeApproved = [] }) => {
  const [ { user } ]:any = useStore()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [ play ]:any = useSound(sound, {
    interrupt: true,
  })
  const [ isOrdersToBeApproval, setIsOrdersToBeApproval ] = useState(false)

  const renderSnackContent = (key:any) => {
    return (
      <div className={ cn(styles.snack) }>
        <div className={ cn(styles.left) }>
          <i className={ ICONS.excellent } />
        </div>
        <div className={ cn(styles.message) }>
          <strong>Une nouvelle commande </strong> vient d’etre ajoutée à votre liste de commandes
        </div>
        <button
          className={ cn('reset') }
          onClick={ () => {
            closeSnackbar(key)
          } }
        >
          <i className={ ICONS.close } />
        </button>
      </div>
    )
  }

  useEffect(() => {
    //  check for new orders and play sound
    const { authorizeSound, authorizeManualOrder } = user.supplier.spottiesPreferences
    if (authorizeManualOrder === false) {
      if (prevOrdersCount !== 0 && prevOrdersCount < ordersCount) {
        if (authorizeSound) play()
        enqueueSnackbar('', {
          variant: 'success',
          autoHideDuration: 6000,
          content: renderSnackContent,
        })
      }
    } else {
      setIsOrdersToBeApproval(true)
    }
  }, [ play, prevOrdersCount, ordersCount ])

  return (
    isOrdersToBeApproval ? (
      orderToBeApproved.map((element:any) => {
        return (
          <Modal dialogMaxWidth="sm" key={ element.id }>
            <NotificationOrderToApprove
              order={ element }
              orderToBeApprovedLength={ orderToBeApproved.length }
            />
          </Modal>
        )
      })
    ) : (
      null
    )
  )
}

export default GlobalNotification
