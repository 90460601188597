import { useQuery, useMutation, useQueryClient } from 'react-query'
import { callApi, optimisticUpdateItem } from '@helpers/api'
import { REFETCH_ORDERS_INTERVAL } from '@src/config/main'
import { ORDER_STATUS } from '@src/config/api'

const QUERY_NAME = 'orders'
const ENDPOINT = 'orders'

export const useOrdersInProgress = (isConfirmed:boolean = true) => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?status_ne=${ORDER_STATUS.completed.id}`,
  }), {
    enabled: isConfirmed,
    retry: false,
    // refetchOnWindowFocus: false, // refetch when window comes to focus
    // refetchOnReconnect: false, // refetch when browser reconnects to server
    // refetchOnMount: false, // refetch when component mounts
    refetchInterval: REFETCH_ORDERS_INTERVAL, // refetch every ms
  })
}

export const useOrdersCompleted = () => {
  return useQuery('orders-completed', () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?status=${ORDER_STATUS.completed.id}&_sort=datePickup:desc`,
  }), {
    // refetchOnWindowFocus: false, // refetch when window comes to focus
    // refetchOnReconnect: false, // refetch when browser reconnects to server
    // refetchOnMount: false, // refetch when component mounts
    // refetchInterval: REFETCH_ORDERS_INTERVAL, // refetch every ms
  })
}

export const useOrdersCompletedOrRefused = () => {
  return useQuery('orders-completed-or-refused', () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?status=${ORDER_STATUS.completed.id}&status=${ORDER_STATUS.refusedBySupplier.id}&_sort=datePickup:desc`,
  }), {
    // refetchOnWindowFocus: false, // refetch when window comes to focus
    // refetchOnReconnect: false, // refetch when browser reconnects to server
    // refetchOnMount: false, // refetch when component mounts
    // refetchInterval: REFETCH_ORDERS_INTERVAL, // refetch every ms
  })
}

export function useUpdateOrder() {
  const queryClient = useQueryClient()
  return useMutation(
    (values:any) => callApi({
      method: 'PUT',
      endpoint: `${ENDPOINT}/${values.id}`,
      data: values,
    }),
    {
      onMutate: (values) => {
        return optimisticUpdateItem({ queryClient, queryName: QUERY_NAME, values })
      },
      // If the mutation fails, use the value returned from onMutate to roll back
      // @ts-ignore
      onError: (error, values, rollback) => rollback(),
      // Always refetch after error or success:
      onSettled: () => {
        // queryClient.invalidateQueries(QUERY_NAME)
      },
    },
  )
}
