import React, { FC } from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import { isWidthUp } from '@material-ui/core/withWidth'
import { useMuiWidth } from '@hooks/index'
import { ORDER_PICKUP_WARNING_TIME } from '@src/config/main'
import { ORDER_STATUS } from '@src/config/api'
import { ICONS } from '@src/config/theme'
import styles from './OrderHead.module.scss'

interface Props{
  reference:string
  status:string
  read:boolean
  isPrint:boolean
  expanded:boolean
  datePickup:string
  datePickupFmt:string
  firstname:string
  handlePrint:any,
  displayArrow:boolean
}

const OrderHead : FC<Props> = ({
  reference, status, read, isPrint, expanded, datePickup, datePickupFmt, firstname, handlePrint, displayArrow,
}) => {
  const width = useMuiWidth()
  const timeBeforePickup = Math.max(0, dayjs(datePickup).diff(dayjs(), 'minute'))
  const timeBeforePickupFmt = `${timeBeforePickup}min`
  const isWarning = timeBeforePickup <= ORDER_PICKUP_WARNING_TIME
  && (status === ORDER_STATUS.inPreparation.id
  || status === ORDER_STATUS.acceptedBySupplier.id)
  

  const renderTimeoutOrName = () => {
    return status === ORDER_STATUS.inPreparation.id || status === ORDER_STATUS.acceptedBySupplier.id ? (
      <div className={ cn(styles.info, isWarning && styles.isWarning) }>
        <h3>Reste</h3>
        <strong>{timeBeforePickupFmt}</strong>
      </div>
    ) : (
      <div className={ styles.info }>
        <h3>Prénom</h3>
        <strong>{firstname}</strong>
      </div>
    )
  }

  const renderPrintBtn = () => {
    return (
      <div className={ cn(styles.info, styles.print, styles[status]) }>
        <button onClick={ handlePrint } className={ cn('reset', styles.printBtn) }>
          <i className={ ICONS.print } />
          {isPrint && (
          <div className={ cn(styles.checked) }>
            <i className={ ICONS.check } />
          </div>
          )}
        </button>
      </div>
    )
  }

  return (
    <div className={ cn(styles.head, styles[status]) }>
      <div className={ styles.left }>
        <div className={ cn(styles.hasBeenRead, !read && styles.no) } data-testid="order_hasBeenRead" />
        <div className={ styles.info }>
          <h3>Commande</h3>
          <strong>#{reference}</strong>
        </div>
        <div className={ cn(styles.info, isWarning && styles.isWarning) }>
          <h3>Retrait</h3>
          <strong>{datePickupFmt}</strong>
        </div>
        {renderTimeoutOrName()}
      </div>

      <div className={ styles.right }>
        {
            isWarning && isWidthUp('md', width) && (
            <div className={ cn(styles.info, styles.warning) }>
              <i className={ ICONS.alerte } />
            </div>
            )
          }
        {
          renderPrintBtn()
        }
        {displayArrow && <i className={ `icon-${expanded ? 'expand_less' : 'expand_more'}` } />}
      </div>
    </div>
  )
}

export default OrderHead
