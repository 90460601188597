export const IS_PROD = process.env.NODE_ENV === 'production'
export const REFETCH_ORDERS_INTERVAL = 30000 // mseconds
export const REFETCH_SUPPLIER_INTERVAL = 60000 // mseconds
export const ORDER_PICKUP_WARNING_TIME = 2 // minutes
export const PREPARATION_TIME_INC = 5 // minutes
export const PREPARATION_TIME_MIN = 5 // minutes
export const ORDER_TO_APPROVE_TIMEOUT = 3 // minutes
export const SALES_CHART = {
  duration: 15,
}

