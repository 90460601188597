
export const ORDER_STATUS = {
  inPreparation: {
    id: 'inPreparation',
    label: 'En attente',
  },
  wait4Collect: {
    id: 'wait4Collect',
    label: 'Prête',
  },
  acceptedBySupplier: {
    id: 'acceptedBySupplier',
    label: 'Accepté par le spotties',
  },
  collected: {
    id: 'collected',
    label: 'Remise',
  },
  completed: {
    id: 'completed',
    label: 'Complète',
  },
  refusedBySupplier: {
    id: 'refusedBySupplier',
    label: 'Refusée',
  },
  wait4SupplierApproval: {
    id: 'wait4SupplierApproval',
    label: 'En attente de validation',
  },
}

export const API_ERRORS_MESSAGES = [
  {
    id: 'Auth.form.error.invalid',
    message: 'Identifiant ou mot de passe invalide',
  },
  {
    id: 'Auth.form.error.code.provide',
    message: 'Le lien de réinitialisation est incorrect ou a expiré',
  },
  {
    id: 'Auth.form.error.user.not-exist',
    message: 'Cet email n’existe pas',
  },
  // not trad
  {
    id: 'Auth.form.error.email.provide',
    message: 'Please provide your username or your e-mail.',
  },
  {
    id: 'Auth.form.error.password.provide',
    message: 'Please provide your password.',
  },
  {
    id: 'Auth.form.error.blocked',
    message: 'Votre compte à été bloqué par un administrateur',
  },
  {
    id: 'Auth.form.error.password.matching',
    message: 'Passwords do not match.',
  },
  {
    id: 'Auth.form.error.params.provide',
    message: 'Incorrect params provided.',
  },
  {
    id: 'Auth.form.error.email.format',
    message: 'Please provide valid email address.',
  },
  
  {
    id: 'Auth.advanced.allow_register',
    message: 'Register action is currently disabled.',
  },
  {
    id: 'Auth.form.error.password.provide',
    message: 'Please provide your password.',
  },
  {
    id: 'Auth.form.error.email.provide',
    message: 'Please provide your email.',
  },
  {
    id: 'Auth.form.error.password.format',
    message: 'Your password cannot contain more than three times the symbol `$`.',
  },
  {
    id: 'Auth.form.error.role.notFound',
    message: 'Impossible to find the default role.',
  },
  {
    id: 'Auth.form.error.email.format',
    message: 'Please provide valid email address.',
  },
  {
    id: 'Auth.form.error.email.taken',
    message: 'Email is already taken.',
  },
  {
    id: 'Auth.form.error.username.taken',
    message: 'Username already taken',
  },
]
