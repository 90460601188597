// see _variables.scss
export const COLORS = {
  primary: '#7eefb6',
  secondary: '#2a295c',
  blueLight: '#e1e6f3',
  blueLighter: '#f4f5f8',
  greyLight: '#f4f5f8',
}

// _icons.scss
export const ICONS = {
  excellent: 'icon-excellent',
  close: 'icon-close',
  comment: 'icon-comment',
  carton: 'icon-carton',
  arrow_back: 'icon-arrow_back',
  time1: 'icon-time1',
  time: 'icon-time',
  vegan: 'icon-vegan',
  timer: 'icon-timer',
  visibility: 'icon-visibility',
  visibility_off: 'icon-visibility_off',
  couverts: 'icon-couverts',
  volaille: 'icon-volaille',
  credit_card: 'icon-credit_card',
  crustace: 'icon-crustace',
  handle: 'icon-handle',
  alerte: 'icon-alerte',
  arrow_right: 'icon-arrow_right',
  boeuf: 'icon-boeuf',
  cacaouette: 'icon-cacaouette',
  card_giftcard: 'icon-card_giftcard',
  carotte: 'icon-carotte',
  check_circle: 'icon-check_circle',
  check: 'icon-check',
  checkempty: 'icon-checkempty',
  atoms: 'icon-atoms',
  csv: 'icon-csv',
  distance: 'icon-distance',
  edit: 'icon-edit',
  epice: 'icon-epice',
  euro: 'icon-euro',
  expand_less: 'icon-expand_less',
  expand_more: 'icon-expand_more',
  face: 'icon-face',
  file_download: 'icon-file_download',
  frigo: 'icon-frigo',
  garbage: 'icon-garbage',
  gluten: 'icon-gluten',
  grade: 'icon-grade',
  help_outline: 'icon-help_outline',
  infos: 'icon-infos',
  laitage: 'icon-laitage',
  left: 'icon-left',
  list: 'icon-list',
  live_help: 'icon-live_help',
  lock: 'icon-lock',
  mail_outline: 'icon-mail_outline',
  minus: 'icon-minus',
  more: 'icon-more',
  more2: 'icon-more2',
  noisette: 'icon-noisette',
  notifications: 'icon-notifications',
  oeuf: 'icon-oeuf',
  ouvert: 'icon-ouvert',
  pdf: 'icon-pdf',
  perm_phone_msg: 'icon-perm_phone_msg',
  phonelink_ring: 'icon-phonelink_ring',
  photo_camera: 'icon-photo_camera',
  pin: 'icon-pin',
  plat_chaud: 'icon-plat-chaud',
  plat_froid: 'icon-plat-froid',
  poisson: 'icon-poisson',
  porc: 'icon-porc',
  price: 'icon-price',
  print: 'icon-print',
  profil: 'icon-profil',
  profil2: 'icon-profil2',
  right: 'icon-right',
  sales: 'icon-sales',
  settings: 'icon-settings',
  spotties: 'icon-spotties',
  sucre: 'icon-sucre',
  menu: 'icon-menu',
}

// https://material-ui.com/customization/theming/
export const THEME = {
  ...COLORS,
  mui: {
    breakpoints: {
      keys: [ 'xs', 'sm', 'md', 'lg', 'xl' ],
      values: {
        xs: 0,
        sm: 568,
        md: 760,
        lg: 1200,
        xl: 1600,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      primary: { main: COLORS.primary },
      secondary: { main: COLORS.secondary },
      action: {
        selected: '#dcebfb',
        hover: '#dcebfb',
      },
    },
    typography: {
      h6: {
        fontFamily: 'inherit',
      },
      button: {
        fontFamily: 'inherit',
      },
    },
    overrides: {
      MuiFormControlLabel: {
        label: {
          fontSize: 14,
          color: '#49487f',
          fontFamily: 'CircularStd-Book',
        },
      },
      MuiSvgIcon: {
        root: {
          width: 18,
        },
      },
    },
  },
  snackbar: {
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    classes: {
      variantSuccess: 'snack--success',
      variantError: 'snack--error',
      variantWarning: 'snack--warning',
      variantInfo: 'snack--info',
    },
  },
}
