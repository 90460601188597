import React from 'react'
import cn from 'classnames'
import styles from './OrderTotal.module.scss'

interface Props{
  totalFmt:string
}

const OrderTotal: React.FC<Props> = ({ totalFmt }) => {
  return (
    <div className={ cn(styles.OrderTotal) }>
      {/* <div className={ styles.line }>
          Total HT
          <div className={ styles.price }>{totalWTFmt}</div>
        </div> */}
      <div className={ `${styles.line} ${styles.totalTTC}` }>
        Total TTC
        <div className={ styles.price }>{totalFmt}</div>
      </div>
    </div>
  )
}

export default OrderTotal
