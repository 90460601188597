import React, { useEffect } from 'react'
import { useStore } from '@src/store'
import { userActions } from '@store/reducers/user'
import { useSupplier } from '@src/store/hooks/supplier'
import { useAppStatus } from '@src/store/hooks/status'
import SimpleLoader from '@cmp/ui/loading/SimpleLoader'
import Layout from '@cmp/layouts/main/Layout'
import { useOrdersInProgress } from '@src/store/hooks/orders'
import { usePrevious } from '@src/hooks'
import { getOrdersByStatus } from '@src/store/selectors/orders'
import GlobalNotification from './_shared/globalNotification/GlobalNotification'
import Routes from './routes/Routes'
import Offline from './pages/offline/Offline'

const App = () => {
  const [ { user }, dispatch ]:any = useStore()
  const { authenticated = {} } = user
  const { supplierId = null } = authenticated
  const { data: supplier } = useSupplier(supplierId)
  const { data: appStatus, status: queryAppStatus } = useAppStatus()
  const isAuthenticated = !!Object.keys(authenticated).length
  const isConfirmed = isAuthenticated && authenticated.confirmed
  // for notif on new order
  const { data: orders = [] } = useOrdersInProgress(isConfirmed)
  const orderToBeApproved = getOrdersByStatus('wait4SupplierApproval')(orders)
  const prevOrdersCount:number = usePrevious(orders.length) || 0
    
  useEffect(() => {
    // check if we can get a user based on stored token
    userActions.get(dispatch)
  }, [])

  useEffect(() => {
    // store the supplier attached to the user once
    if (isAuthenticated && supplier) {
      userActions.setSupplier({ supplier }, dispatch)
    }
  }, [ isAuthenticated, supplier ])

  if (queryAppStatus === 'loading' || user.isLoadingGet || (isAuthenticated && !user.supplier)) {
    return <SimpleLoader full />
  }

  if (queryAppStatus !== 'success' || !appStatus.supplier) {
    return <Offline />
  }
  
  return (
    <>
      {user.supplier && (
        <GlobalNotification
          prevOrdersCount={ prevOrdersCount }
          ordersCount={ orders.length }
          orderToBeApproved={ orderToBeApproved }
        />
      )}
      <Layout authenticated={ authenticated }>
        <Routes
          isConfirmed={ isConfirmed }
          isAuthenticated={ isAuthenticated }
          appStatus={ appStatus }
        />
      </Layout>
    </>
  )
}

export default App
