
import {
  getToken, callApi, storeToken, removeToken,
} from '@helpers/api'

const REMEMBER_LS = 'rememberMe'

const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  SET_SUPPLIER: 'SET_SUPPLIER',
}

export const initialState = {
  authenticated: {},
  supplier: null,
  isLoadingLogin: true,
  isLoadingGet: true,
}

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: true,
      }
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        isLoadingGet: true,
      }
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        authenticated: payload.user,
        isLoadingLogin: false,
      }
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        authenticated: payload.user,
        isLoadingGet: false,
      }
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: false,
      }
    case actionTypes.GET_USER_FAILURE:
      return {
        ...state,
        isLoadingGet: false,
      }
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoadingLogin: false,
        isLoadingGet: false,
        authenticated: {},
        supplier: null,
      }
    case actionTypes.SET_SUPPLIER:
      return {
        ...state,
        supplier: payload.supplier,
      }
    default:
      return state
  }
}


// AUTH
// _____________________________________________


const login = async ({
  values, onSuccess, setErrors, setSubmitting,
}, dispatch) => {
  dispatch({ type: actionTypes.LOGIN_REQUEST })

  const { rememberMe, ...data } = values
  
  if (rememberMe) {
    localStorage.setItem(REMEMBER_LS, 1)
  } else {
    localStorage.removeItem(REMEMBER_LS)
  }

  try {
    const { jwt, user } = await callApi({
      method: 'POST',
      endpoint: 'auth/local',
      data,
      withAuth: false,
    })
    storeToken(jwt)
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: { user } })
    onSuccess()
  } catch (error) {
    // console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.LOGIN_FAILURE })
  }
  setSubmitting(false)
}

const changePassword = async ({
  values, onSuccess, setErrors, setSubmitting,
}, dispatch) => {
  // dispatch({ type: actionTypes.LOGIN_REQUEST })

  try {
    const user = await callApi({
      method: 'PUT',
      endpoint: 'users/me',
      data: values,
    })
    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, payload: { user } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const resetPassword = async ({
  values, onSuccess, setErrors, setSubmitting,
}, dispatch) => {
  // dispatch({ type: actionTypes.LOGIN_REQUEST })

  try {
    const res = await callApi({
      method: 'POST',
      endpoint: 'auth/reset-password',
      data: values,
      withAuth: false,
    })
    console.log({ res })
    // dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, payload: { user } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const forgotPassword = async ({
  values, onSuccess, setErrors, setSubmitting,
}, dispatch) => {
  try {
    const res = await callApi({
      method: 'POST',
      endpoint: 'auth/forgot-password',
      data: values,
      withAuth: false,
    })
    dispatch({ type: actionTypes.FORGOT_PASSWORD_SUCCESS, payload: { res } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const logout = (dispatch) => {
  removeToken()
  dispatch({ type: actionTypes.LOGOUT_SUCCESS })
  // window.location.reload()
}

const get = async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_REQUEST })
  const token = getToken()
  const rememberMe = localStorage.getItem(REMEMBER_LS)

  if (!token || !rememberMe) {
    storeToken({})
    return dispatch({ type: actionTypes.GET_USER_FAILURE })
  }

  try {
    const user = await callApi({
      method: 'GET',
      endpoint: 'users/me',
    })
    return dispatch({ type: actionTypes.GET_USER_SUCCESS, payload: { user } })
  } catch (error) {
    // console.log({ error })
    return dispatch({ type: actionTypes.GET_USER_FAILURE })
  }
}

const setSupplier = ({ supplier }, dispatch) => {
  return dispatch({ type: actionTypes.SET_SUPPLIER, payload: { supplier } })
}

export const userActions = {
  login,
  logout,
  get,
  resetPassword,
  forgotPassword,
  changePassword,
  setSupplier,
}
