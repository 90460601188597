import React, { FC } from 'react'
import { userActions } from '@store/reducers/user'
import { useStore } from '@store/index'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { PATHS } from '@src/config/nav'
import ReactGA from 'react-ga'
import styles from './LogoutBtn.module.scss'


const LogoutBtn : FC = () => {
  const [ , dispatch ]:any = useStore()
  const history = useHistory()
  return (
    <button
      className={ cn('reset', styles.LogoutBtn) }
      onClick={ () => {
        history.push(PATHS.authenticate)
        userActions.logout(dispatch)
        ReactGA.event({
          category: 'User',
          action: 'click logout',
        })
      } }
    >
      <i className="icon-atoms-ic-power" />
      <span className={ cn(styles.label) }>Déconnexion</span>
    </button>
  )
}

export default LogoutBtn
