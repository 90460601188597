import { useQuery, useMutation, useQueryClient } from 'react-query'
import { callApi } from '@helpers/api'
import { REFETCH_SUPPLIER_INTERVAL } from '@src/config/main'

const QUERY_NAME = 'supplier'
const ENDPOINT = 'suppliers'

export const useSupplier = (id:number) => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}/${id}`,
  }), {
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
    refetchInterval: REFETCH_SUPPLIER_INTERVAL, // refetch every ms
    enabled: !!id,
  })
}

export function useUpdateSupplier() {
  const queryClient = useQueryClient()
  return useMutation(
    (params:any) => callApi({
      method: 'PUT',
      endpoint: `${ENDPOINT}/${params.values.id}`,
      data: params.values,
    }),
    {
      onMutate: (params) => {
        const { id, ...valuesToUpdate } = params.values
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(QUERY_NAME)
        
        // Snapshot the previous value
        // eslint-disable-next-line prefer-object-spread
        const previousData = Object.assign({}, queryClient.getQueryData(QUERY_NAME))
        
        // Optimistically update to the new value
        const newData = { ...previousData, ...valuesToUpdate }
        queryClient.setQueryData(QUERY_NAME, newData)

        // Return the snapshotted value for rollback
        return () => queryClient.setQueryData(QUERY_NAME, previousData)
      },
      // If the mutation fails, use the value returned from onMutate to roll back
      // @ts-ignore
      onError: (error:any, params, rollback:any) => {
        if (params.setErrors) {
          params.setErrors({ fromApi: error })
          params.setSubmitting(false)
        }
        return rollback()
      },
      onSuccess: (res, params) => {
        if (params.onSuccess) {
          params.setSubmitting(false)
          params.onSuccess()
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        // queryClient.invalidateQueries(QUERY_NAME)
      },
    },
  )
}
