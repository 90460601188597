import React, { FC } from 'react'
import Header from '@cmp/layouts/header/Header'
import SubHeader from '@cmp/layouts/subheader/SubHeader'
import { isWidthDown } from '@material-ui/core/withWidth'
import { useMuiWidth } from '@hooks/index'
import styles from './Layout.module.scss'

interface Props{
  children:React.ReactNode,
  authenticated:any
}

const Layout : FC<Props> = ({ children, authenticated }) => {
  const isAuthenticated = !!Object.keys(authenticated).length
  const isConfirmed = isAuthenticated && authenticated.confirmed
  const width = useMuiWidth()

  if (!isAuthenticated || !isConfirmed) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <div className={ styles.Layout }>
      <div className={ styles.header }>
        <Header />
      </div>
      <div className={ styles.content }>
        {!isWidthDown('sm', width) && <SubHeader />}
        {children}
      </div>
    </div>
  )
}

export default Layout
