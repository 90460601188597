import React, { FC } from 'react'
import { v1 as uuid } from 'uuid'
import { OrderTotal, Item as ItemType } from '@interfaces/index'
import styles from './OrderDetail.module.scss'
import OrderDetailAside from './aside/OrderDetailAside'
import Item from './item/Item'

interface Props{
  cart:any
  cutlery:boolean
  specificInformation: string | null
  orderTotal:OrderTotal
  isVente?:boolean
}

const OrderDetail : FC<Props> = ({
  cart, cutlery, specificInformation: orderInformation, orderTotal, isVente = false,
}) => {
  const renderItems = () => {
    if (!cart.items) return 'N/A'
    return (
      <ul>
        { cart.items.map((item:ItemType) => (
          <div key={ uuid() }>
            <Item { ...item } />
          </div>
        )) }
      </ul>
    )
  }
  
  const renderAside = () => {
    const asideProps = { cutlery, orderInformation, orderTotal }
    return <OrderDetailAside { ...asideProps } isVente={ isVente } />
  }

  return (
    <div className={ `${styles.DetailCommande}` }>
      <div className={ styles.items }>
        <h3>Détail de la commande</h3>
        {renderItems()}
      </div>
      {renderAside()}
    </div>
  )
}

export default OrderDetail
