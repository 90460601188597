import React from 'react'
import cn from 'classnames'
import { Item as ItemType, OptionGroup, Option } from '@interfaces/index'
import styles from './Item.module.scss'

interface Props extends ItemType{
  isPrint?:boolean
}

const Item: React.FC<Props> = ({
  product, quantity, specificInformation, options, isPrint,
}) => {
  const renderOption = (option:Option) => {
    const { title } = option
    return (
      <div key={ title }>
        {quantity}x {title}
      </div>
    )
  }

  const renderOptions = () => {
    return options && options.map((optionGroup:OptionGroup) => {
      const { title, product_options } = optionGroup
      return (
        <div key={ title }>
          <h3>{title}</h3>
          {product_options && product_options.map((option:Option) => renderOption(option))}
        </div>
      )
    })
  }

  return (
    <div>
      <li className={ cn(styles.Item, isPrint && styles.print) }>
        <div className={ styles.qty }>{quantity}x</div>
        <div className={ styles.product }>
          <div className={ cn('mb5', styles.productTitle) }>{product.title}</div>
          {options && renderOptions()}
          {specificInformation && (
          <div className={ styles.specificInformationWrapper }>
            <h3>Commentaires</h3>
            <div className={ styles.specificInformation }>{specificInformation}</div>
          </div>
          )}
        </div>

      </li>
    </div>
  )
}

export default Item
