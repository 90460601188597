import React, { useState } from 'react'
import cn from 'classnames'
import { useUpdateOrder } from '@src/store/hooks/orders'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styles from '../GlobalNotification.module.scss'

const OrderRefusedModal = ({ orderId, orderRef, backToPreviousModal }) => {
  const { mutate: updateOrder } = useUpdateOrder()
  const [ reasonSelected, setReasonSelected ] = useState('')
  const [ isReasonSelected, setIsReasonSelected ] = useState()

  const reasonsList = [
    'Produits non disponibles',
    'Ne peut honorer la commande',
    'Sur le point de fermer',
  ]
  const selectReason = (event) => {
    const reasonText = event.target.value
    setReasonSelected(reasonText)
    setIsReasonSelected(true)
  }

  const submitReason = () => {
    if (isReasonSelected === true) {
      updateOrder({ id: orderId, status: 'refusedBySupplier', refusedExplanation: reasonSelected })
    } else {
      setIsReasonSelected(false)
    }
  }
  return (
    <div className={ cn(styles.modalRefused) }>
      <h1>Refus de votre commande #{orderRef}</h1>
      <RadioGroup aria-label="reasons" name="reasons" value={ reasonSelected } onChange={ selectReason }>
        {reasonsList.map((reason, index) => {
          return (
            <div key={ reason } className={ index === 1 ? cn(styles.borderChoice) : null }>
              <FormControlLabel value={ reason } control={ <Radio /> } label={ reason } />
            </div>
          )
        })}
      </RadioGroup>
      {isReasonSelected === false && (
      <p className="error">
        Merci de sélectionner une raison valable
      </p>
      )}
      <div className={ cn(styles.actions) }>
        <button
          className={ cn(styles.back, 'cta cta--transparent') }
          onClick={ () => backToPreviousModal(false) }
        >
          <i className={ cn(styles.arrow_back, 'icon-arrow_back') } />
          <span>Revenir en arrière</span>
        </button>
        <button
          className={ cn(styles.refuse, 'cta cta--red') }
          onClick={ () => submitReason() }
        >
          Refuser
        </button>
      </div>
    </div>
  )
}

export default OrderRefusedModal
