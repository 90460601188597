import { Link } from '@interfaces/index'
import { ICONS } from './theme'

export const PATHS = {
  root: '/',
  authenticate: '/authenticate',
  forgotPassword: '/forgotPassword',
  firstConnexion: '/firstConnexion',
  resetPassword: '/resetPassword',
  commandes: {
    root: '/commandes',
    encours: '/commandes/encours',
    stocks: '/commandes/stocks',
  },
  carte: '/carte',
  promotions: '/promotions',
  ventes: '/ventes',
  params: {
    root: '/params',
    profil: '/params/profil',
    notifications: '/params/notifications',
  },
  aide: '/aide',
  openingHours: '/heures-d-ouverture',
  stocks: '/stocks',
  // equipe: '/equipe',
}

export const LINKS:Link[] = [
  {
    to: PATHS.root,
    label: 'Accueil',
    icon: ICONS.spotties,
    // isMobile: false,
  },
  {
    to: PATHS.commandes.root,
    label: 'Commandes',
    icon: ICONS.notifications,
  },
  {
    to: PATHS.stocks,
    label: 'Stocks',
    icon: ICONS.carton,
    isMobileOnly: true,
  },
  {
    to: PATHS.carte,
    label: 'À la carte',
    icon: ICONS.list,
    isMobile: false,
  },
  {
    to: PATHS.promotions,
    label: 'Promotions',
    icon: ICONS.sales,
  },
  {
    to: PATHS.ventes,
    label: 'Ventes',
    icon: ICONS.euro,
    isMobile: false,
  },
  // {
  //   to: PATHS.equipe,
  //   label: 'Équipe',
  //   icon: 'icon-face',
  //   isMobile: false,
  // },
  {
    to: PATHS.aide,
    label: 'Aide',
    icon: ICONS.infos,
  },
  {
    to: PATHS.params.root,
    label: 'Paramètres',
    icon: ICONS.settings,
  },
  {
    to: PATHS.openingHours,
    label: 'Horaires d’ouverture',
    isMain: false,
  },
  {
    to: PATHS.authenticate,
    label: 'Se connecter',
    isMain: false,
  },
]
