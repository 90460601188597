import React from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import { getOpenCloseStatus } from '@src/store/selectors/supplier'
import { Link } from 'react-router-dom'
import { PATHS } from '@src/config/nav'
import { useStore } from '@store/index'
import { Hidden } from '@material-ui/core'
import styles from './OpenCloseSign.module.scss'


const OpenCloseSign = () => {
  const [ { user: { supplier } } ]:any = useStore()
  
  if (!supplier) return null

  const nowDay = dayjs().format('dddd').toLowerCase()
  const nowHour = dayjs().format('HH:mm')
  const { isOpen, openAt, closeAt } = getOpenCloseStatus({ nowDay, nowHour })(supplier)
  
  return (
    <Link to={ PATHS.openingHours } className="silent">
      <div className={ cn(styles.OpenCloseSign) }>
        <div>
          <div className={ styles.flex }>
            <div className={ cn(styles.openclose, isOpen && styles.open) }>
              {isOpen ? 'Ouvert' : 'Fermé'}
            </div>
            <Hidden mdDown>
              <i className="icon-edit" />
            </Hidden>
          </div>
          <Hidden smDown>
            { closeAt && <div className={ styles.next }>Fermeture à {closeAt}</div> }
            { openAt && <div className={ styles.next }>Ouverture à {openAt}</div> }
          </Hidden>
        </div>
      </div>
    </Link>
  )
}

export default OpenCloseSign
