import { createSelector, defaultMemoize } from 'reselect'
import { sortBy } from '@src/utils/array'
import dayjs from 'dayjs'
import { euro } from '@src/utils/text'
import { ORDER_STATUS } from '@src/config/api'

const ordersSelector = (orders) => orders
const ordersCompletedSelector = (ordersCompleted) => ordersCompleted

export const ordersFormatted = createSelector(
  ordersSelector,
  (orders) => {
    return orders.map((order) => {
      const { datePickup, cart: { cartInfos: { totalWT, total } } } = order
      return {
        ...order,
        datePickupFmt: dayjs(datePickup).format('HH:mm'),
        orderTotal: {
          totalWTFmt: totalWT ? euro(totalWT) : '-',
          totalFmt: euro(total),
        },
      }
    })
  },
)

export const getOrdersByStatus = defaultMemoize((status) => createSelector(
  ordersFormatted,
  (orders) => {
    const fltered = orders.filter((order) => order.status === status)
    const AscOrDesc = ORDER_STATUS.wait4SupplierApproval.id === status ? 'DESC' : 'ASC'
    const sorted = sortBy(fltered, 'datePickupFmt', AscOrDesc)
    return sorted
  },
))

export const countUnreadOrders = createSelector(
  ordersSelector,
  (orders) => orders.filter((order) => !order.read).length,
)


export const getBestSales = createSelector(
  ordersCompletedSelector,
  (orders) => {
    const sales = orders
      .reduce((acc, cur) => {
        const { cart: { items } } = cur
        return acc.concat(items)
      }, [])
      .map((item) => {
        const { id, title, image } = item.product
        return {
          id,
          title,
          image,
          quantity: item.quantity,
        }
      })
      .reduce((acc, cur) => {
        const product = !!acc.length && acc.find((p) => p.title === cur.title)
        if (!product) {
          acc.push(cur)
        } else {
          product.quantity = product.quantity + cur.quantity
        }
        return acc
      }, [])

    const next = sortBy(sales, 'quantity', 'DESC')// .slice(0, 12)

    // get position (could be ex-aequo..)
    // return next.reduce((acc, cur) => {
    //   const latest = acc[acc.length - 1]
    //   if (!latest) {
    //     cur.position = 1
    //   } else if (latest.quantity > cur.quantity) {
    //     cur.position = latest.position + 1
    //   } else {
    //     cur.position = latest.position
    //   }
    //   acc.push(cur)
    //   return acc
    // }, [])

    return next
  },
)

export const getNoSales = defaultMemoize((allProducts) => createSelector(
  getBestSales,
  (bestSales) => {
    return allProducts
      .map(({ id, title, image }) => {
        const isSold = !!bestSales.find((p) => p.id === id)
        return !isSold && {
          id, title, image, quantity: 0,
        }
      })
      .filter((p) => p)
  },
))


export const getLastDays = (duration) => {
  return Array(duration).fill(0).map((_, i) => {
    const date = dayjs().subtract(i, 'day').format('DD/MM')
    return date
  }).reverse()
}


export const getSalesVolume = defaultMemoize((duration) => createSelector(
  ordersCompletedSelector,
  (orders) => {
    const initialAcc = getLastDays(duration).map((d) => {
      return {
        day: d,
        amount: 0,
      }
    })

    const sales = orders
      .reduce((acc, cur) => {
        const datePickup = dayjs(cur.datePickup).format('DD/MM')
        const day = acc.find((p) => p.day === datePickup)
        if (day) {
          day.amount = day.amount + cur.cart.cartInfos.total
        }
        return acc
      }, initialAcc)
      .map((day) => day.amount)

    return sales
  },
))

export const getOrdersCount = defaultMemoize((duration) => createSelector(
  ordersCompletedSelector,
  (orders) => {
    const initialAcc = getLastDays(duration).map((d) => {
      return {
        day: d,
        amount: 0,
      }
    })

    const count = orders
      .reduce((acc, cur) => {
        const datePickup = dayjs(cur.datePickup).format('DD/MM')
        const day = acc.find((p) => p.day === datePickup)
        if (day) {
          day.amount = day.amount + 1
        }
        return acc
      }, initialAcc)
      .map((day) => day.amount)

    return count
  },
))

