import { createSelector, defaultMemoize } from 'reselect'
import { sortBy } from '@src/utils/array'
import { formatHour } from '@src/helpers/supplier'


const supplierSelector = (supplier) => supplier

export const getOpenCloseStatus = defaultMemoize(({ nowDay, nowHour }) => createSelector(
  supplierSelector,
  (supplier) => {
    const { hoursWorking, active } = supplier.days[nowDay]
    const slots = hoursWorking.map((hw) => ({
      oh: formatHour(hw.openingHour),
      ch: formatHour(hw.closingHour),
    }))
    const hours = slots
      .reduce((acc, cur) => acc.concat([
        { hour: cur.oh, is: 'opening' },
        { hour: cur.ch, is: 'closing' },
      ]), [])
      .concat({ hour: nowHour })
    const hoursSorted = sortBy(hours, 'hour')
    const nowIndex = hoursSorted.findIndex((h) => h.hour === nowHour)
    const before = hours[nowIndex - 1]
    const after = hours[nowIndex + 1]
  
    // wont reopen today
    if (!after || !active) return {}
    // closed
    if (!before || (before.is === 'closing' && after.is === 'opening')) {
      return {
        openAt: after.hour,
      }
    }
    // open
    return {
      isOpen: true,
      closeAt: after.hour,
    }
  },
))
