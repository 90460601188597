import React, { FC } from 'react'
import cn from 'classnames'
import { OrderTotal as OrderTotalType } from '@interfaces/index'
import styles from './OrderDetailAside.module.scss'
import OrderTotal from './total/OrderTotal'


interface Props{
  cutlery:boolean
  orderInformation : string | null
  orderTotal:OrderTotalType
  isVente?:boolean
}

const OrderDetailAside : FC<Props> = ({
  cutlery, orderInformation, orderTotal, isVente = false,
}) => {
  const renderNotes = () => {
    return orderInformation && (
      <div className={ styles.notes }>{orderInformation}</div>
    )
  }
  const renderCouverts = () => {
    return (
      <div className={ styles.couverts }>
        {cutlery && <i className={ cn('icon-check', styles.check, styles.active) } />}
        <i className="icon-couverts" />
        {cutlery ? 'Avec couverts' : 'Pas de couverts'}
      </div>
    )
  }

  return (
    <div className={ styles.OrderDetailAside }>
      <h3>Notes</h3>
      {renderNotes()}
      <h3>Couverts</h3>
      {renderCouverts()}
      {
        !isVente
        && (
        <>
          <h3>Total payé</h3>
          <OrderTotal { ...orderTotal } />
        </>
        )
      }
      
    </div>
  )
}

export default OrderDetailAside
