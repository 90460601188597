import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PATHS } from '@config/nav'
import { useStore } from '@store'

const PrivateRoute = ({
  component: Component, path, ...rest
}) => {
  const [ { user: { authenticated } } ] = useStore()
  const isAuthenticated = !!Object.keys(authenticated).length
  const isConfirmed = authenticated.confirmed

  return (
    <Route
      { ...rest }
      render={ (props) => {
        if (isAuthenticated && isConfirmed) return <Component { ...props } />
        if (isAuthenticated && !isConfirmed) return <Redirect to={ PATHS.firstConnexion } />
        return <Redirect to={ PATHS.authenticate } />
      } }
    />
  )
}

export default PrivateRoute
