import * as userStore from '@store/reducers/user'
import ReactGA from 'react-ga'

export const initialState = {
  user: userStore.initialState,
}

export const reducer = ({
  user,
}, action) => {
  ReactGA.event({
    category: 'Store',
    action,
  })
  return {
    user: userStore.reducer(user, action),
  }
}
