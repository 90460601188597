import { Slot } from '@src/interfaces'

// from string 00:00xxxx to string 00:00
export const formatHour = (hour:string) => {
  return hour.slice(0, 5)
}

// from string 00:00 to int (0)000
export const getHourInt = (hour:string) => {
  return parseInt(hour.replace(':', ''), 10)
}

// from int int (0)000 to string 00:00
export const getHourStringFromInt = (hour:number) => {
  let str = hour.toString()
  str = str.length === 3 ? `0${str}` : str
  return `${str.substring(0, 2)}:${str.substring(2)}`
}


export const getFromToHour = (slots:Slot[], type:string, index:number) => {
  let previousHour = slots[index].openingHour
  if (type === 'openingHour') {
    if (slots[index - 1]) {
      previousHour = slots[index - 1].closingHour
    } else {
      previousHour = '00:00'
    }
  }
  let nextHour = slots[index].closingHour
  if (type === 'closingHour') {
    if (slots[index + 1]) {
      nextHour = slots[index + 1].openingHour
    } else {
      nextHour = '24:00'
    }
  }

  const fhm = previousHour.split(':')
  const fh = parseInt(fhm[0], 10)
  const fm = parseInt(fhm[1], 10)
  const from = fh * 4 + (fm === 15 ? 2 : fm === 30 ? 3 : fm === 45 ? 4 : index >= 0 ? 1 : 0)
  const thm = nextHour.split(':')
  const th = parseInt(thm[0], 10)
  const tm = parseInt(thm[1], 10)
  const to = th * 4 - (tm >= 30 ? -1 : 1)

  return { from, to }
}

export const getDefaultHoursOptions = (slots:Slot[], type:string, index:number) => {
  const { from, to } = getFromToHour(slots, type, index)
  const options = []
  for (let i = from; i <= to; i++) {
    const decimal = Math.round(((i / 4) % 1) * 10) / 10
    const h = Math.floor(i / 4)
    const hh = h < 10 ? `0${h}` : h
    const m = decimal === 0.3 ? '15' : decimal === 0.5 ? '30' : decimal === 0.8 ? '45' : '00'
    const val = `${hh}:${m}`
    options.push({ label: val, value: val })
  }
  return options
}


export const getMinOpeningHour = (slots:Slot[]) => {
  if (slots.length > 0) {
    const lastSlot = slots[slots.length - 1]
    const chm = lastSlot.closingHour.split(':')
    const ch = parseInt(chm[0], 10)
    const cm = parseInt(chm[1], 10)
    const nh = cm === 45 ? ch + 1 : ch
    const nm = cm === 0 ? 15 : cm === 15 ? 30 : cm === 30 ? 45 : 0
    const nextHourInt = nh * 100 + nm

    // next opening is max 23:45
    const nextHour = Math.min(2345, nextHourInt)
    return getHourStringFromInt(nextHour)
  }
  return '00:00'
}

export const canAddSlot = (slots:Slot[]) => {
  if (slots.length > 0) {
    const lastSlot = slots[slots.length - 1]
    const ch = getHourInt(lastSlot.closingHour)
    // yes if last closing hour is before 23:00
    return ch < 2345
  }
  return true
}
