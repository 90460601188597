import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { PATHS } from '@config/nav'
import PrivateRoute from '@cmp/utils/PrivateRoute'
import AuthRoute from '@cmp/utils/AuthRoute'
import SimpleLoader from '@cmp/ui/loading/SimpleLoader'
import { AppStatus } from '@src/interfaces'
import Offline from '../pages/offline/Offline'

const Authenticate = lazy(() => import('@cmp/pages/authenticate/Authenticate'))
const FirstConnexion = lazy(() => import('@cmp/pages/authenticate/FirstConnexion'))
const ForgotPassword = lazy(() => import('@cmp/pages/authenticate/ForgotPassword'))
const ResetPassword = lazy(() => import('@cmp/pages/authenticate/ResetPassword'))
const NotFound = lazy(() => import('@cmp/pages/notFound/NotFound'))
const Home = lazy(() => import('@cmp/pages/home/Home'))
const Commandes = lazy(() => import('@cmp/pages/commandes/Commandes'))
const OpeningHours = lazy(() => import('@cmp/pages/openingHours/OpeningHours'))
const Carte = lazy(() => import('@cmp/pages/carte/Carte'))
const Stocks = lazy(() => import('@cmp/pages/commandes/stocks/Stocks'))
const Params = lazy(() => import('@cmp/pages/params/Params'))
const Aide = lazy(() => import('@cmp/pages/aide/Aide'))
const Promotions = lazy(() => import('@cmp/pages/promotions/Promotions'))
const Ventes = lazy(() => import('@cmp/pages/ventes/Ventes'))

interface Props{
  isAuthenticated:boolean
  isConfirmed:boolean
  appStatus: AppStatus
}

const Routes: React.FC<Props> = ({ isAuthenticated, isConfirmed, appStatus }) => {
  const ready = {
    auth: appStatus.auth,
    home: appStatus.order && appStatus.offer && appStatus.product,
    openingHours: appStatus.supplier,
    commandes: appStatus.supplier && appStatus.order,
    stocks: appStatus.product,
    carte: appStatus.product,
    promotions: appStatus.product && appStatus.offer,
    ventes: appStatus.order,
  }

  return (
    <Suspense fallback={ <SimpleLoader full /> }>
      <Switch>
        <AuthRoute exact path={ PATHS.authenticate } component={ ready.auth ? Authenticate : Offline } />
        <Route exact path={ PATHS.forgotPassword } component={ ready.auth ? ForgotPassword : Offline } />
        <Route exact path={ PATHS.resetPassword } component={ ready.auth ? ResetPassword : Offline } />
        {
          isAuthenticated && !isConfirmed
          && <Route exact path={ PATHS.firstConnexion } component={ ready.auth ? FirstConnexion : Offline } />
        }
        <PrivateRoute exact path={ PATHS.root } component={ ready.home ? Home : Offline } />
        <PrivateRoute exact path={ PATHS.openingHours } component={ ready.openingHours ? OpeningHours : Offline } />
        <PrivateRoute path={ PATHS.commandes.root } component={ ready.commandes ? Commandes : Offline } />
        <PrivateRoute path={ PATHS.stocks } component={ ready.stocks ? Stocks : Offline } />
        <PrivateRoute exact path={ PATHS.carte } component={ ready.carte ? Carte : Offline } />
        <PrivateRoute exact path={ PATHS.promotions } component={ ready.promotions ? Promotions : Offline } />
        <PrivateRoute exact path={ PATHS.ventes } component={ ready.ventes ? Ventes : Offline } />
        <PrivateRoute exact path={ PATHS.aide } component={ Aide } />
        <PrivateRoute path={ PATHS.params.root } component={ Params } />
        <Route component={ NotFound } />
      </Switch>
    </Suspense>
  )
}

export default Routes
