/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

class Analytics extends React.Component {
  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GA_CODE)
    ReactGA.pageview(this.props.location.pathname)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactGA.pageview(this.props.location.pathname)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(Analytics)
