/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react'
import OrderDetail from '@cmp/pages/commandes/orders/ordersCol/order/detail/OrderDetail'
import OrderHead from '@cmp/pages/commandes/orders/ordersCol/order/head/OrderHead'
import cn from 'classnames'
import { useUpdateOrder } from '@src/store/hooks/orders'
import dayjs from 'dayjs'
import { ORDER_TO_APPROVE_TIMEOUT } from '@src/config/main'
import { ORDER_STATUS } from '@src/config/api'
import sound from '@assets/audio/sound.mp3'
import styles from '../GlobalNotification.module.scss'
import OrderRefusedModal from './OrderRefusedModal'

interface Props{
  order:any,
  orderToBeApprovedLength:number
}

const NotificationOrderToApprove: React.FC<Props> = ({ order, orderToBeApprovedLength }) => {
  const {
    id, reference, status, read, isPrint, expanded, datePickup, datePickupFmt, handlePrint,
    cart, cutlery, specificInformation, orderTotal, updated_at, supplier,
  } = order
  
  const { firstname } = order.user
  const headProps = {
    reference,
    status,
    read,
    isPrint,
    expanded,
    datePickup,
    datePickupFmt,
    handlePrint,
    firstname,
    displayArrow: false,
  }
  const orderToApproveTimeOut = dayjs(updated_at).add(ORDER_TO_APPROVE_TIMEOUT, 'minute') <= dayjs()
  
  const { mutate: updateOrder } = useUpdateOrder()
  const [ isRefused, setIsRefused ] = useState(false)

  useEffect(() => {
    if (orderToApproveTimeOut) {
      updateOrder({
        id: order.id,
        status: ORDER_STATUS.refusedBySupplier.id,
        refusedExplanation: 'N’a pas accepté à temps',
      })
    }
  }, [ orderToApproveTimeOut ])

  useEffect(() => {
    const audio = new Audio()
    audio.src = sound
    if (supplier.spottiesPreferences.authorizeSound && orderToBeApprovedLength === 1) {
      audio.play()
      audio.addEventListener('ended', function() {
        this.currentTime = 0
        this.play()
      }, false)
    }
    return () => {
      audio.pause()
      audio.currentTime = 0
    }
  }, [ ])
  return (
    <>
      {!isRefused ? (
        <>
          <OrderHead { ...headProps } />
          <OrderDetail
            cart={ cart }
            cutlery={ cutlery }
            specificInformation={ specificInformation }
            orderTotal={ orderTotal }
          />
          <div className={ cn(styles.actions) }>
            <button
              className={ cn(styles.refuse, 'cta cta--red') }
              onClick={ () => setIsRefused(true) }
            >
              Refuser
            </button>
            <button
              className={ cn(styles.accept, 'cta') }
              onClick={ () => updateOrder({ id: order.id, status: ORDER_STATUS.acceptedBySupplier.id }) }
            >
              Accepter
            </button>
          </div>
        </>
      ) : (
        <OrderRefusedModal
          orderRef={ reference }
          orderId={ id }
          backToPreviousModal={ (data:boolean) => setIsRefused(data) }
        />
      )}
    </>
  )
}

export default NotificationOrderToApprove
