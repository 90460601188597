import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { StateProvider } from '@store'
import { initialState, reducer } from '@store/reducers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { THEME } from '@config/theme'
import ScrollToTop from '@cmp/utils/ScrollToTop'
import { SnackbarProvider } from 'notistack'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider, focusManager } from 'react-query'
import * as serviceWorker from './serviceWorker'
import App from './components/App'
import './index.scss'
import Analytics from './components/utils/Analytics'

// keep querying out of focus... 
// allows sounds notification
focusManager.setFocused(true)

const queryClient = new QueryClient()
const theme = createMuiTheme(THEME.mui)
const history = createBrowserHistory()

const renderApp = () => {
  return (
    <Router history={ history } basename="/">
      <ScrollToTop>
        <Analytics>
          <StateProvider initialState={ initialState } reducer={ reducer }>
            <MuiThemeProvider theme={ theme }>
              <SnackbarProvider { ...THEME.snackbar }>
                <QueryClientProvider client={ queryClient }>
                  {/* <ReactQueryDevtools initialIsOpen /> */}
                  <App />
                </QueryClientProvider>
              </SnackbarProvider>
            </MuiThemeProvider>
          </StateProvider>
        </Analytics>
      </ScrollToTop>
    </Router>
  )
}

ReactDOM.render(renderApp(), document.getElementById('root'))
serviceWorker.unregister()
